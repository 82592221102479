export default {
  '--app-primary-color': 'hsl(205, 100%, 35%)',
  '--app-secondary-color': 'hsl(205, 100%, 15%)',
  '--app-tertiary-color': 'hsl(205, 50%, 90%)',
  '--app-disabled-color': 'hsl(220, 12%, 95%)',
  '--sidebar-bg-color': 'hsl(205, 100%, 35%)',
  '--sidebar-icon-color': 'hsl(0, 0%, 100%)',
  '--sidebar-text-color': 'hsl(0, 0%, 100%)',
  '--sidebar-active-bg-color': 'hsl(49, 100%, 50%)',
  '--sidebar-active-text-color': 'hsl(0, 0%, 0%)'
};
