import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { CustomIcon } from '~components/index';

interface Props {
  name: string;
  text?: string;
  required: boolean;
  helper?: string | JSX.Element;
}

const InputLabel = ({ name, text, required, helper }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex gap-2">
      <label htmlFor={name} className="fw-semibold">
        <div>
          {text && t(text)}
          {required && (
            <span className="customInput_required ms-1 text-wrap">*</span>
          )}
        </div>
      </label>
      {helper && (
        <OverlayTrigger
          key="auto"
          trigger="click"
          rootClose
          placement="auto"
          overlay={
            <Popover id="popover-basic">
              <Popover.Body>{helper}</Popover.Body>
            </Popover>
          }
        >
          <div className="cursor-pointer">
            <CustomIcon name="questionCircle" />
          </div>
        </OverlayTrigger>
      )}
    </div>
  );
};

export default InputLabel;
