import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '~components/index';
import InputLabel from '../InputLabel/InputLabel';
import type { CommonInputProps } from '../InputsTypes';
import '../inputs.scss';

interface Props extends CommonInputProps {
  defaultValue?: boolean;
  link?: string;
  hookOnChange?: (value: boolean) => void;
  onInputChange?: (value: boolean) => void;
}

const InputCheckbox = ({
  name,
  label,
  placeholder,
  labelHelper,
  disabled = false,
  readOnly = false,
  required = false,
  defaultValue = false,
  design = 'col-12',
  hookError,
  link,
  onInputChange = () => {},
  hookOnChange = () => {}
}: Props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(defaultValue);

  return (
    <div className={`customInput ${design}`}>
      {label && (
        <InputLabel
          helper={labelHelper}
          name={name}
          text={label}
          required={required}
        />
      )}
      <div
        className={`customInput_checkboxContainer ${
          disabled ? 'customInput_disabled' : ''
        }`}
      >
        <input
          type="checkbox"
          id={name}
          disabled={disabled}
          readOnly={readOnly}
          defaultChecked={defaultValue}
          onChange={() => {
            setValue(!value);
            hookOnChange(!value);
            onInputChange(!value);
          }}
        />
        {placeholder && t(placeholder)}
        {link && (
          <div className="ps-2">
            <Button variant="link" fileToDownload={link} text="btns.see" />
          </div>
        )}
      </div>
      {hookError?.message && (
        <p className="customInput_error">{t(hookError?.message)}</p>
      )}
    </div>
  );
};

const MemoizeInputCheckbox = memo(InputCheckbox);
export default MemoizeInputCheckbox;
