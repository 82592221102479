const publicRoutes = {
  LOGIN: '/login',
  REGISTER: '/register'
};

const privateRoutes = {
  PLATFORM: '/platform',
  HOME: '/platform/home',
  WORK_LIST: '/platform/work_list',
  CREATE_LIST: '/platform/create_list',
  FILE_LOAD: '/platform/file_load',
  FILE_HISTORY: '/platform/file_history',
  SPECIALTY: '/platform/specialty',
  PATIENT_LIST: '/platform/patient_list',
  COHORT: '/platform/cohort',
  OBSERVATION_ZONE: '/platform/observation_zone',
  LOCATION_WITH_PATIENTS: '/platform/location_with_patients',
  WORK_UNITS: '/platform/work_units',
  USER_UPDATE: '/platform/user_update',
  USER_CREATE: '/platform/user_create',
  USERS: '/platform/users',
  GROUPS_MANAGEMENT: '/platform/groups_management',
  SCHEDULE_MANAGE: '/platform/schedule_manage',
  MANAGE_COLSUBSIDIO_PATIENTS: '/platform/col_manage_patients'
};

export const ROUTES = {
  ROOT: '/',
  ERROR: '/404',
  ...publicRoutes,
  ...privateRoutes
} as const;
