import { AiFillMedicineBox, AiOutlineInfoCircle } from 'react-icons/ai';
import { BiBlock, BiLogOut, BiRename } from 'react-icons/bi';
import {
  BsHospital,
  BsPeople,
  BsTrashFill,
  BsXCircleFill
} from 'react-icons/bs';
import {
  FaBars,
  FaChartBar,
  FaCheckCircle,
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
  FaChevronUp,
  FaClock,
  FaEye,
  FaFileDownload,
  FaFilter,
  FaList,
  FaPencilAlt,
  FaQuestion,
  FaRegCircle,
  FaRegQuestionCircle,
  FaTable,
  FaUserAltSlash,
  FaUserCheck,
  FaUserEdit
} from 'react-icons/fa';
import {
  FaFileArrowUp,
  FaMagnifyingGlass,
  FaPlus,
  FaRegCircleCheck,
  FaTimeline,
  FaUsersGear,
  FaWpforms
} from 'react-icons/fa6';
import { FiUser } from 'react-icons/fi';
import { GiMedicines } from 'react-icons/gi';
import { GrSchedule } from 'react-icons/gr';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import {
  HiMiniArrowRightCircle,
  HiMiniPencilSquare,
  HiMiniShieldCheck
} from 'react-icons/hi2';
import { IoPeople, IoPerson, IoReload } from 'react-icons/io5';
import { LiaUserInjuredSolid } from 'react-icons/lia';
import { LuFileCheck, LuHeartHandshake, LuNetwork } from 'react-icons/lu';
import {
  MdClose,
  MdHealthAndSafety,
  MdNewReleases,
  MdOutlineCellTower,
  MdOutlineEmail,
  MdOutlineSecurity,
  MdPregnantWoman
} from 'react-icons/md';
import { PiExcludeFill, PiFilesFill } from 'react-icons/pi';
import {
  RiAlertFill,
  RiHotelBedLine,
  RiLockPasswordLine,
  RiUserAddFill,
  RiUserFollowLine
} from 'react-icons/ri';
import { TbTriangleSquareCircleFilled } from 'react-icons/tb';
import { TiCancel } from 'react-icons/ti';
import { VscGraph } from 'react-icons/vsc';

export const ICONS = {
  alertFill: RiAlertFill,
  app_logo: PiExcludeFill,
  bars: FaBars,
  timeline: FaTimeline,
  table: FaTable,
  bedPulse: RiHotelBedLine,
  outlineCheck: FaRegCircleCheck,
  block: BiBlock,
  BsPeople: BsPeople,
  calendar: GrSchedule,
  luHeartHandshake: LuHeartHandshake,
  mdHealthAndSafety: MdHealthAndSafety,
  aiFillMedicineBox: AiFillMedicineBox,
  giMedicines: GiMedicines,
  bsHospital: BsHospital,
  riUserFollowLine: RiUserFollowLine,
  mdPregnantWoman: MdPregnantWoman,
  cancel: TiCancel,
  chartBar: FaChartBar,
  checkCircle: FaCheckCircle,
  chevronDown: FaChevronDown,
  chevronLeft: FaChevronLeft,
  chevronRight: FaChevronRight,
  chevronUp: FaChevronUp,
  circle: FaRegCircle,
  clock: FaClock,
  close: MdClose,
  emailOutline: MdOutlineEmail,
  eye: FaEye,
  fileArrowUp: FaFileArrowUp,
  fileCheck: LuFileCheck,
  fileDownload: FaFileDownload,
  files: PiFilesFill,
  filter: FaFilter,
  infoCircle: AiOutlineInfoCircle,
  listPatient: FaList,
  lockOutline: RiLockPasswordLine,
  logout: BiLogOut,
  magnifyingGlass: FaMagnifyingGlass,
  network: LuNetwork,
  notCheck: BsXCircleFill,
  outlineSecurity: MdOutlineSecurity,
  pencil: FaPencilAlt,
  people: IoPeople,
  person: IoPerson,
  plus: FaPlus,
  powerBi: VscGraph,
  question: FaQuestion,
  questionCircle: FaRegQuestionCircle,
  releases: MdNewReleases,
  reload: IoReload,
  rename: BiRename,
  report: HiOutlineDocumentReport,
  surveyFill: FaWpforms,
  towerControl: MdOutlineCellTower,
  trashFill: BsTrashFill,
  triangleSquareCircleFilled: TbTriangleSquareCircleFilled,
  user: FiUser,
  userAddFill: RiUserAddFill,
  userAltSlash: FaUserAltSlash,
  userCheck: FaUserCheck,
  userEdit: FaUserEdit,
  userInjured: LiaUserInjuredSolid,
  usersGear: FaUsersGear,
  hiMiniPencilSquare: HiMiniPencilSquare,
  hiMiniArrowRightCircle: HiMiniArrowRightCircle,
  hiMiniShieldCheck: HiMiniShieldCheck
} as const;

export const ICON_SIZES = {
  XS: 14,
  S: 16,
  MD: 24,
  LG: 32,
  XL: 50
} as const;
